import React from 'react';
import { Container, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    width: '100%',
  },
  item: {
    width: '100%',
  },
  list: {
    '& > *': {
      margin: 8,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
}));

const GameCenter: React.FC = () => {
  const { banner, item, list } = useStyles();
  return (
    <Container>
      <img className={banner} src="images/gamecenter/game-center.png" alt="" />
      <div className={list}>
        <div>
          <img className={item} src="images/gamecenter/game-1.png" alt="" />
        </div>
        <div>
          <img className={item} src="images/gamecenter/game-2.png" alt="" />
        </div>
        <div>
          <img className={item} src="images/gamecenter/game-3.png" alt="" />
        </div>
      </div>
    </Container>
  );
};

export default GameCenter;
