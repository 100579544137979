import React, { useState } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { Phone } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { chatItems } from '../configs/hotmenus-teenage';

const useStyles = makeStyles((theme) => ({
  colorBox: {
    alignItems: 'center',
    backgroundColor: ({ color }) => `${color}`,
    color: 'white',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-evenly',
    padding: '16px'
  },
  colorBoxBtn: {
    cursor: 'pointer',
    padding: '10px',
    transform: ({ hover }) => `translateY(${hover ? '-4px' : '0px'})`
  },
  colorBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  item: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '16px 5px',
    transform: ({ hover }) => `translateY(${hover ? '-4px' : '0px'})`
  },
  root: {
    minHeight: '130px'
  },
  section: theme.section,
  paperLeft: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    padding: '10px 30px',
    alignItems: 'center'
  },
  thirdSection: {
    color: theme.palette.primary.main
  }
}));

const HotMenusTeenage = ({ items, onItemClick }) => {
  const { section, root, paperLeft, thirdSection } = useStyles();
  return (
    <>
      <Box p={0.5}>
        <Grid className={classNames(section, root)} container spacing={1}>
          {items.map((item, index) => (
            <Grid key={index} item xs>
              <HotMenuTeenage {...item} onClick={onItemClick} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box p={0.5}>
        <Grid className={classNames(section, root)} container spacing={1}>
          {chatItems.map((item, index) => (
            <Grid key={index} item xs>
              <ColorBox {...item} onClick={onItemClick} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box p={0.5}>
        <Paper>
          <Grid container justify="space-between" className={thirdSection}>
            <Grid item xs={3}>
              <Paper elevation={0} className={paperLeft}>
                <Phone />
                <Typography variant="h6">สายด่วน</Typography>
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                alignContent="center"
                justify="space-evenly"
                style={{ height: '100%' }}
              >
                <Typography>1663</Typography>
                <Typography>สายด่วนปรึกษาเอดส์ และท้องไม่พร้อม</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={1}
              alignContent="center"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                style={{
                  backgroundColor: 'red',
                  width: '1px',
                  height: '80%'
                }}
              ></Box>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                alignContent="center"
                justify="space-evenly"
                style={{ height: '100%', marginRight: '30px' }}
              >
                <Typography>1323</Typography>
                <Typography>สายด่วนสุขภาพจิต</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export const HotMenuTeenage = ({ image, title, url, onClick }) => {
  const [hover, setHover] = useState(false);
  const { item } = useStyles({ hover });
  return (
    <Paper
      className={item}
      onClick={() => onClick(url)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img src={`${image}`} alt={title} />
      <Typography>{title}</Typography>
    </Paper>
  );
};

const ColorBox = ({ color, items, onClick }) => {
  const { colorBox } = useStyles({
    color
  });
  return (
    <Paper className={colorBox}>
      <Grid container>
        {items.map((item, index) => (
          <Grid key={index} item xs={12} lg={6}>
            <ColorBoxContent {...item} onClick={onClick} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

const ColorBoxContent = ({
  title,
  image,
  description,
  buttonImage,
  url,
  onClick
}) => {
  const [hover, setHover] = useState(false);
  const { colorBoxBtn } = useStyles({
    hover
  });
  return (
    <Box display="flex" height="100%" flexDirection="column" justifyContent="center" alignItems="center" px={0} py={1}>
      {title && title !== '' && <Typography variant="h5">{title}</Typography>}
      {image && image !== '' && <img src={`${image}`} alt="" />}
      {description && description !== '' && (
        <Typography dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {buttonImage && buttonImage !== '' && (
        <img
          className={colorBoxBtn}
          src={`${buttonImage}`}
          alt=""
          onClick={() => onClick(url)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      )}
    </Box>
  );
};

export default HotMenusTeenage;
