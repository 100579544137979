export const items = [
  {
    title: 'ปรึกษากลัวท้อง/ท้องไม่พร้อม',
    image: 'images/home-teenage/HotMenu-01.png',
    url: '/service',
  },
  {
    title: 'ถุงยางฟรีมีที่ไหนบ้าง',
    image: 'images/home-teenage/HotMenu-02.png',
    url: '/condom',
  },
  {
    title: 'กลัวติดโรค หาที่ปรึกษา',
    image: 'images/home-teenage/HotMenu-03.png',
    url: '/service',
  },
  {
    title: 'สถานที่รับ PrEP/PEP',
    image: 'images/home-teenage/HotMenu-04.png',
    url: '/preppep',
  },
];

export const chatItems = [
  {
    color: '#2FC4C1',
    items: [
      {
        title: '',
        image: 'images/home-teenage/love-care-header.png',
        description: `บริการให้การปรึกษาออนไลน์ 
        โรคติดต่อทางเพศสัมพันธ์ ท้องไม่พร้อม 
        คุมกำเนิด การรังแก สัมพันธภาพ สุขภาพจิต 
        เปิดให้บริการเวลา เที่ยงวัน ถึง เที่ยงคืน
        `,
        url: '',
      },
      {
        title: 'ห้องแชทเลิฟแคร์',
        image: '',
        description: '',
        buttonImage: 'images/home-teenage/love-care-btn.png',
        url: '',
      },
    ],
  },
  {
    color: '#FF5BA5',
    items: [
      {
        title: '',
        image: 'images/home-teenage/hi-teen-gang-header.png',
        description: 'ไขปัญหาคาใจ ความรัก เพศ เรียน และอื่นๆ',
        url: '',
      },
      {
        title: 'ห้องแชทแห่งความลับ',
        image: '',
        description: '',
        buttonImage: 'images/home-teenage/hi-teen-gang-btn.png',
        url: '',
      },
    ],
  },
];
