import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    backgroundColor: '#FFF',
    height: 300,
    width: '100%'
  },
  bannerContainer: {
    height: '100%'
  },
  bannerImage: {
    alignItems: 'center',
    backgroundImage: `url('images/landing/banner-lgbt.png')`,
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  bannerContent: {
    backgroundColor: '#FFFA',
    padding: 8,
    textAlign: 'center'
  },
  item: {
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    height: 320,
    margin: '16px 24px',
    position: 'relative',
    width: 400
  },
  itemContent: {
    alignItems: 'center',
    backgroundColor: 'white',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: 80,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0
  },
  itemText: {
    border: 'solid 1px #C671E6',
    borderRadius: 8,
    color: '#C671E6',
    padding: `2px 8px`
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    padding: 24,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      flexDirection: 'column'
    }
  },
  msm: {
    backgroundImage: `url('images/landing/lgbt-msm.png')`
  },
  tg: {
    backgroundImage: `url('images/landing/lgbt-tg.png')`
  }
}));

const Lgbt: React.FC = () => {
  const {
    banner,
    bannerContainer,
    bannerImage,
    bannerContent,
    item,
    itemContent,
    itemText,
    menu,
    msm,
    tg
  } = useStyles();
  const history = useHistory();

  return (
    <>
      <div className={banner}>
        <Container className={bannerContainer} maxWidth="lg">
          <div className={bannerImage} />
        </Container>
      </div>
      <Container className={menu} maxWidth="lg">
        <div
          className={clsx(item, msm)}
          onClick={() => history.push('/home-msm')}
        >
          <div className={itemContent}>
            <div className={itemText}>
              <Typography>MSM</Typography>
            </div>
            <Typography>Men who have Sex with Men</Typography>
          </div>
        </div>
        <div
          className={clsx(item, tg)}
          onClick={() => history.push('/home-tg')}
        >
          <div className={itemContent}>
            <div className={itemText}>
              <Typography>TG</Typography>
            </div>
            <Typography>Transgender</Typography>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Lgbt;
