export const items = [
  {
    title: 'หน่วยบริการ',
    content:
      'ค้นหาหน่วยบริการที่เกี่ยวกับโรคติดต่อทางเพศสัมพันธ์ เอชไอวี และเอดส์ ได้ที่นี่ ',
    background: 'images/home/bg-content-about.png',
    icon: 'images/home/icon-about.png',
    url: '/search'
  },
  {
    title: 'เอชไอวีและเอดส์',
    content:
      'โรคเอดส์ (Aids) คือกลุ่มอาการภูมิคุ้มกันเสื่อมเกิดจากการรับเชื้อ HIV เข้าไปทำลายเม็ดเลือดขาวที่เป็นแหล่งสร้างภูมิคุ้มกันโรคทำให้ภูมิคุ้มกันโรคลดน้อยลง ทำให้เชื้อโรคฉวยโอกาสแทรกซ้อนเข้าสู่ร่างกายได้ง่ายขึ้น',
    background: 'images/home/bg-content-hiv.png',
    icon: 'images/home/icon-hiv.png',
    url: '/hiv'
  },
  {
    title: 'บริการตรวจ/รักษา',
    content: 'ค้นหาศูนย์สถานบริการข้อมูลโรงพยาบาล สถานบริการ ศูนย์บริการต่างๆ',
    background: 'images/home/bg-content-service.png',
    icon: 'images/home/icon-service.png',
    url: '/service'
  }
];
