import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  banner: {
    backgroundColor: '#FEBE02',
    height: 500,
    width: '100%',
  },
  bannerImage: {
    alignItems: 'center',
    backgroundImage: `url('images/landing/banner.png')`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  menuImage: {
    cursor: 'pointer',
    marginTop: 24,
    objectFit: 'contain',
    width: '100%',
  },
});

const Landing: React.FC = () => {
  const { banner, bannerImage, menuImage } = useStyles();
  const history = useHistory();
  return (
    <>
      <div className={banner}>
        <div className={bannerImage} />
      </div>
      <Container maxWidth="lg">
        <img
          className={menuImage}
          src="images/landing/menu-1.png"
          alt=""
          onClick={() => history.push('/home-teenage')}
        />
        <img
          className={menuImage}
          src="images/landing/menu-2.png"
          alt=""
          onClick={() => history.push('/lgbt')}
        />
        <img
          className={menuImage}
          src="images/landing/menu-3.png"
          alt=""
          onClick={() => history.push('/home-general')}
        />
      </Container>
    </>
  );
};

export default Landing;
