import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import ContactList from './ContactList';
import LinkList from './LinkList';
import TextViewer from './TextViewer';
import Banner from './Banner';
import BannerSwipeable from './BannerSwipeable';
import Title from './Title';
import Container from './Container';
import SearchHeader from './SearchHeader';
import DialogContact from './DialogContact';
import AboutUsMap from './AboutUsMap';
import ContactForm from './ContactForm';
import OnlineAppointment from './OnlineAppointment';
import FeaturedPost from './FeaturedPost';
import PostGroup from './PostGroup';
import PostList from './PostList';
import FileList from './FileList';
import Map from './MyGoogleMap';
import HotMenus from './HotMenus';
import HotMenusTeenage from './HotMenusTeenage';
import HotMenusTransgender from './HotMenusTransgender';
import Hilights from './Hilights';
import VideoModal from './VideoModal';

import { baseMap } from '../configs/mapSchema';
import { items as hotMenuItems } from '../configs/hotmenus';
import { items as hilightItems } from '../configs/hilights';
import { items as hotMenuTeenageItems } from '../configs/hotmenus-teenage';
import { items as hotMenuTGItems } from '../configs/hotmenus-tg';
import { items as hotMenuMSMItems } from '../configs/hotmenus-msm';

import MapService from 'common/services/map';
import { sendMail } from 'common/services/feedback';

const noContentTypes = [
  'aboutusmap',
  'contactus',
  'hilight',
  'hotmenu',
  'hotmenu-teenage',
  'hotmenu-transgender',
  'hotmenu-msm'
];

const SinglePage = ({ data, specialTags, history }) => {
  const renderWidget = (block, index) => {
    if (!noContentTypes.includes(block.type) && !block.content) {
      return null;
    }
    switch (block.type) {
      case 'aboutusmap':
        return <AboutUsMap />;
      case 'appointment':
        return (
          <OnlineAppointment
            title="นัดตรวจเลือดออนไลน์"
            description="ตรวจเอชไอวีออนไลน์กับคลินิคที่ร่วมรายการ รวดเร็ว ง่าย ราคาถูก และเป็นความลับ"
            items={block.content}
            onItemClick={(url) => url && window.open(url, '_blank')}
          />
        );
      case 'banner':
        return (
          <Banner
            key={index}
            {...block.content}
            onItemClick={(url) =>
              url.startsWith('/')
                ? history.push(url)
                : window.open(url, '_blank')
            }
          />
        );
      case 'bannerswipeable':
        return (
          <BannerSwipeable
            items={block.content}
            onItemClick={(url) =>
              url.startsWith('/')
                ? history.push(url)
                : window.open(url, '_blank')
            }
          />
        );
      case 'contact':
        return <Contacts content={block.content} specialTags={specialTags} />;
      case 'contactus':
        return <ContactForm onSubmit={sendMail} />;
      case 'file':
        return <Files content={block.content} />;
      case 'hilight':
        return (
          <Hilights
            items={hilightItems}
            onItemClick={(url) => history.push(url)}
          />
        );
      case 'hotmenu':
        return (
          <HotMenus
            items={hotMenuItems}
            onItemClick={(url) => history.push(url)}
          />
        );
      case 'hotmenu-teenage':
        return (
          <HotMenusTeenage
            items={hotMenuTeenageItems}
            onItemClick={(url) => history.push(url)}
          />
        );
      case 'hotmenu-transgender':
        return (
          <HotMenusTransgender
            items={hotMenuTGItems}
            onItemClick={(url) => history.push(url)}
          />
        );
      case 'hotmenu-msm':
        return (
          <HotMenusTransgender
            items={hotMenuMSMItems}
            onItemClick={(url) => history.push(url)}
          />
        );
      case 'link':
        return <Links content={block.content} paginate={block.paginate} />;
      case 'mapwithcontact':
        return <MapWithContacts content={block.content} />;
      case 'post':
        return (
          <Posts
            content={block.content}
            onClickItem={(item) => history.push(`/post/${item.id}`)}
          />
        );
      case 'text':
        return <TextViewer content={block.content} asFaq={block.asFaq} />;
      case 'title':
        return <Title icon={block.content.icon} text={block.content.text} />;
      case 'video':
        return <Videos content={block.content} paginate={block.paginate} />;
      default:
        return null;
    }
  };
  const renderBlock = (block, index) => {
    return (
      <Container
        key={index}
        fullWidth={block.fullWidth}
        dark={block.dark}
        section={block.section}
        style={{ paddingTop: index !== 0 && '8px' }}
        backgroundImage={
          block.type === 'appointment' && 'images/online-appointment-bg.png' // hard coded, sorry =(
        }
      >
        {renderWidget(block)}
      </Container>
    );
  };
  return <>{data.blocks.map(renderBlock)}</>;
};

const Contacts = ({ content, specialTags }) => {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagSelected, setTagSelected] = useState('none');
  const [districtSelected, setDistrictSelected] = useState('none');
  const [contacts, setContacts] = useState(content.items || []);
  const [filterSSO, setFilterSSO] = useState(false);
  const [filterOF, setFilterOF] = useState(false);
  const [filterGold, setFilterGold] = useState(false);
  const [filterOverTime, setFilterOverTime] = useState(false);
  const [filterTrain, setFilterTrain] = useState(false);

  const searchHandle = () => {
    if (!content.items) return;
    console.log(
      `search tagSelected:${tagSelected} districtSelected:${districtSelected}`
    );
    let items = content.items;
    if (content.specialTag && tagSelected !== 'none') {
      items = specialTags.find(
        (item) => item.id === content.specialTags[tagSelected]
      ).items;
    }

    setContacts(
      items.filter(
        (item) =>
          (tagSelected === 'none' || _.includes(item.tags, tagSelected)) &&
          (districtSelected === 'none' ||
            item.district.trim() === districtSelected) &&
          (!filterSSO ||
            (item.privilege && _.includes(item.privilege, 'sso'))) &&
          (!filterOF || (item.privilege && _.includes(item.privilege, 'of'))) &&
          (!filterGold ||
            (item.privilege && _.includes(item.privilege, 'gold'))) &&
          (!filterOverTime || item.overtime === 'yes') &&
          (!filterTrain || (item.train && item.train !== '-'))
      )
    );

    setPage(0);
  };

  useEffect(() => {
    setTags(
      MapService.contactTagsByPage(content.name).map((tag) => ({
        text: tag.name,
        value: tag.id
      }))
    );
  }, [content.name]);

  return (
    <>
      <SearchHeader
        icon={content.icon}
        title={content.title}
        subtitle={content.description}
        filter={content.filter}
        primaryLabel="ประเภทบริการ"
        primaryChoices={[
          { text: 'เลือกบริการที่ต้องการ', value: 'none' },
          ...tags
        ]}
        onPrimarySelect={setTagSelected}
        primarySelected={tagSelected}
        secondaryLabel="เขต"
        secondaryChoices={[
          { text: 'เลือกเขต', value: 'none' },
          ...MapService.districts()
        ]}
        onSecondarySelect={setDistrictSelected}
        secondarySelected={districtSelected}
        onSearch={searchHandle}
        contactFilter={content.filter}
        filterSSO={filterSSO}
        setFilterSSO={setFilterSSO}
        filterOF={filterOF}
        setFilterOF={setFilterOF}
        filterGold={filterGold}
        setFilterGold={setFilterGold}
        filterOverTime={filterOverTime}
        setFilterOverTime={setFilterOverTime}
        filterTrain={filterTrain}
        setFilterTrain={setFilterTrain}
      />
      <ContactList
        items={contacts}
        currentPage={page}
        itemPerPage={6}
        onPrevPage={() => setPage(page - 1)}
        onNextPage={() => setPage(page + 1)}
        onItemClick={(item) => setSelected(item)}
        onClickTel={(tel) => tel && window.open(`tel:${tel}`)}
        onClickWeb={(url) => url && window.open(url, '_blank')}
      />
      <DialogContact
        open={selected !== null}
        handleClose={() => setSelected(null)}
        {...selected}
      />
    </>
  );
};

const Links = ({ content, paginate }) => {
  const [page, setPage] = useState(0);
  return (
    <>
      <SearchHeader
        icon={content.icon}
        title={content.title}
        filter={content.filter}
      />
      <LinkList
        items={content.items}
        currentPage={page}
        // use infinity to display all links
        itemPerPage={paginate ? 8 : Infinity}
        onPrevPage={() => setPage(page - 1)}
        onNextPage={() => setPage(page + 1)}
        onLinkClick={(item) => item.url && window.open(item.url, '_blank')}
      />
    </>
  );
};

let swap = false;
const Posts = ({ content, onClickItem }) => {
  const [page, setPage] = useState(0);
  if (content.length === 1) {
    const featured = (
      <FeaturedPost item={content[0]} swap={swap} onClick={onClickItem} />
    );
    swap = !swap;
    return featured;
  } else if (content.length === 4) {
    return <PostGroup items={content} onItemClick={onClickItem} />;
  }
  return (
    <PostList
      items={content}
      currentPage={page}
      itemPerPage={6}
      onPrevPage={() => setPage(page - 1)}
      onNextPage={() => setPage(page + 1)}
      onArticleClick={onClickItem}
    />
  );
};

const Files = ({ content }) => {
  const [page, setPage] = useState(0);
  return (
    <FileList
      items={content}
      itemPerPage={4}
      currentPage={page}
      onPrevPage={() => setPage(page - 1)}
      onNextPage={() => setPage(page + 1)}
      onFileClick={(item) => window.open(item.fileLink, '_blank')}
    />
  );
};

const Videos = ({ content, paginate }) => {
  const [page, setPage] = useState(0);
  const [videoUrl, setVideoUrl] = useState('');
  const [open, setOpen] = useState(false);
  return (
    <>
      <SearchHeader
        icon={content.icon}
        title={content.title}
        filter={content.filter}
      />
      <LinkList
        items={content.items}
        currentPage={page}
        // use infinity to display all links
        itemPerPage={paginate ? 8 : Infinity}
        onPrevPage={() => setPage(page - 1)}
        onNextPage={() => setPage(page + 1)}
        onLinkClick={(item) => {
          setVideoUrl(item.url);
          setOpen(true);
        }}
      />
      <VideoModal
        videoUrl={videoUrl}
        open={open}
        onClose={() => setOpen(false)}
        autoplay={true}
      />
    </>
  );
};

const MapWithContacts = ({ content }) => {
  const [selected, setSelected] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagSelected, setTagSelected] = useState('none');
  const [districtSelected, setDistrictSelected] = useState('none');
  const [contacts, setContacts] = useState(
    content.items.map((contact) => ({
      ...contact,
      lat: parseFloat(contact.latitude) || 0,
      lng: parseFloat(contact.longitude) || 0
    })) || []
  );
  const [filterSSO, setFilterSSO] = useState(false);
  const [filterOF, setFilterOF] = useState(false);
  const [filterGold, setFilterGold] = useState(false);
  const [filterOverTime, setFilterOverTime] = useState(false);
  const [filterTrain, setFilterTrain] = useState(false);
  const searchHandle = () => {
    if (!content.items) return;
    console.log(
      `search tagSelected:${tagSelected} districtSelected:${districtSelected}`
    );

    setContacts(
      content.items
        .filter(
          (item) =>
            (tagSelected === 'none' || _.includes(item.tags, tagSelected)) &&
            (districtSelected === 'none' ||
              item.district.trim() === districtSelected) &&
            (!filterSSO ||
              (item.privilege && _.includes(item.privilege, 'sso'))) &&
            (!filterOF ||
              (item.privilege && _.includes(item.privilege, 'of'))) &&
            (!filterGold ||
              (item.privilege && _.includes(item.privilege, 'gold'))) &&
            (!filterOverTime || item.overtime === 'yes') &&
            (!filterTrain || (item.train && item.train !== '-'))
        )
        .map((item) => ({
          ...item,
          lat: parseFloat(item.latitude) || 0,
          lng: parseFloat(item.longitude) || 0
        }))
    );
  };

  useEffect(() => {
    setTags(
      MapService.contactTagsByPage(content.name).map((tag) => ({
        text: tag.name,
        value: tag.id
      }))
    );
  }, [content.name]);

  return (
    <>
      <SearchHeader
        icon={content.icon}
        title={content.title}
        subtitle={content.description}
        filter={content.filter}
        primaryLabel="ประเภทบริการ"
        primaryChoices={[
          { text: 'เลือกบริการที่ต้องการ', value: 'none' },
          ...tags
        ]}
        onPrimarySelect={setTagSelected}
        primarySelected={tagSelected}
        secondaryLabel="เขต"
        secondaryChoices={[
          { text: 'เลือกเขต', value: 'none' },
          ...MapService.districts()
        ]}
        onSecondarySelect={setDistrictSelected}
        secondarySelected={districtSelected}
        onSearch={searchHandle}
        contactFilter={true}
        filterSSO={filterSSO}
        setFilterSSO={setFilterSSO}
        filterOF={filterOF}
        setFilterOF={setFilterOF}
        filterGold={filterGold}
        setFilterGold={setFilterGold}
        filterOverTime={filterOverTime}
        setFilterOverTime={setFilterOverTime}
        filterTrain={filterTrain}
        setFilterTrain={setFilterTrain}
      />
      <Map
        height={500}
        {...baseMap}
        icon={{
          url: baseMap.icon,
          scaledSize: { width: 27, height: 32 }
        }}
        selectedIcon={{
          url: baseMap.selectedIcon,
          scaledSize: { width: 27, height: 32 }
        }}
        markers={contacts}
        onMarkerClick={(marker) => setSelected(marker)}
        selected={selected}
        onClickDirection={(info) =>
          window.open(
            `https://www.google.com/maps?ll=${info.lat},${info.lng}&q=${info.title}&amp;z=14`,
            '_blank'
          )
        }
      />
    </>
  );
};

export default withRouter(SinglePage);
